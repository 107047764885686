@import '~antd/dist/antd.css';

@media print {
  .__jivoMobileButton {
    display: none !important;
  }
  jdiv#jvlabelWrap {
    display: none !important;
  }
  #root {
    height: 842px;
    overflow: hidden;
  }
}
